/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-06-07
 */

/**
 * create on 2023-06-07.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class TelegramMessageForm {
    private static readonly newLine: string = '\n';
    private _text: string;

    constructor() {
        this._text = '';
    }

    text(text: string): TelegramMessageForm {
        this._text = text;
        return this;
    }

    /*굵은 텍스트*/
    bold(): TelegramMessageForm {
        this._text = `<b>${this._text}</b>`;
        return this;
    }

    /*기울임 텍스트*/
    italic(): TelegramMessageForm {
        this._text = `<i>${this._text}</i>`;
        return this;
    }

    /*밑줄 텍스트*/
    underLine(): TelegramMessageForm {
        this._text = `<u>t${this._text}</u>`
        return this;
    }

    /*취소선 텍스트*/
    strikethrough(): TelegramMessageForm {
        this._text = `<s>${this._text}</s>`
        return this;
    }

    /*code 텍스트*/
    code(): TelegramMessageForm {
        this._text = `<code>${this._text}</code>`
        return this;
    }

    /*텍스트를 작성된 그대로 표기*/
    pre(): TelegramMessageForm {
        this._text = `<pre>${this._text}</pre>`
        return this;
    }

    /*하이퍼링크*/
    hyperlink(): TelegramMessageForm {
        this._text = `<a href=${this._text}>${this._text}</a>`
        return this;
    }

    /*개행 추가*/
    build(): string {
        return this._text + TelegramMessageForm.newLine;
    }
}
