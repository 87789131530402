/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2024-03-07 0007
 */
import {APIS, ApiType, DevType} from "../../common/enum/GlobalContents";
import {TkIdInfo} from "../../common/tkIdInfo/TkIdInfo";
import {StringUtil} from "../../common/lib/util/StringUtil";
import {IAuidInfo} from "../../dm/interface/IAuidInfo";
import {AuidInfo} from "../../common/auidInfo/AuidInfo";
import {AJAX} from "../../common/lib/ajax/AJAX";
import {AJAXOption} from "../../common/lib/ajax/AJAXOption";
import {Uni1Info} from "../../common/uni1Info/Uni1Info";
import {ScriptService} from "./ScriptService";

/**
 * create on 2024-03-07 0007.
 * <p> onetag service </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class OneTagService {
    private readonly adbId;
    constructor() {
        this.adbId = window['ENP_VAR'] !== undefined ?  window['ENP_VAR'].adbId : undefined;
    }
    command(param):void {
        let tkid = this.callAuidAndMerge();
        new ScriptService(param).excute(tkid);
    }


    private callAuidAndMerge(): string {
        let tkId = TkIdInfo.getInstance().getTkId();
        if(StringUtil.isStrictEmpty(tkId)) tkId = TkIdInfo.getInstance().setTkId();
        Uni1Info.getInstance().mergeLeapArrive(tkId!);

        //3rd cookie set auid
        const auidInfo: IAuidInfo | null = AuidInfo.getInstance().getAuidInfo();

        const ajax = AJAX.getInstance();
        const options: AJAXOption = {
            timeout: 1500,
            withCredentials: true
        }

        let request:{}  = auidInfo != null ? auidInfo : {};

        ajax.post(APIS[ApiType.AU_ID][DevType.PROD], request, options, (xhr, response, hasTransmitted) => {
            if (hasTransmitted) {
                /**
                 * auid 싱크를 맞추기 위해 return 받은 auid로 덮어쓴다.
                 * ipInfo의 경우는 최초 발급된 ip로 사용 없데이트 X
                 */

                const parsedData = JSON.parse(response);
                const storageData = {identKey: parsedData['auid'], identNumber: auidInfo != null && auidInfo.ip ? auidInfo.ip : parsedData['ip']}
                this.callMerge(tkId!);
                window.localStorage.setItem('_uni', JSON.stringify(storageData));
            } else {
                this.callMerge(tkId!);
            }
            xhr.onerror = () => {
                this.callMerge(tkId!);
            }
        });
        return tkId!;
    }

    private callMerge(tkid: string): void {
        Uni1Info.getInstance().mergeUn1ID(this.adbId, tkid);
    }
}
