/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-03-15
 */

import {StringUtil} from "../util/StringUtil";

/**
 * create on 2023-03-15.
 * <p> URL QueryString </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class QueryString {
    constructor() {
    }

    convertQueryStringtoObj(url: string): {} {
        let data = {};
        const search = url.split('?')[1];
        if(search !== undefined) {
            search.split('&').forEach((query) => {
                const splitData = query.split('=');
                data[splitData[0]] = !StringUtil.isStrictEmpty(splitData[1]) ? splitData[1].split('#')[0] : '';
            });
        }

        return data;

    }

    getValue(url: string, key: string): string {
        const data = this.convertQueryStringtoObj(url);
        return data[key];
    }

}
