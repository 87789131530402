/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2024-03-07 0007
 */
import {AJAX} from "../../common/lib/ajax/AJAX";
import {AJAXOption} from "../../common/lib/ajax/AJAXOption";
import {APIS, ApiType} from "../../common/enum/GlobalContents";
import {Telegram} from "../../common/lib/telegram/Telegram";
import {StringUtil} from "../../common/lib/util/StringUtil";
import {ScriptInfo} from "../interface/ScriptInterface";
import {ExternalSErvice} from "./ExternalSErvice";
import {URLUtil} from "../../common/lib/util/URLUtil";

/**
 * create on 2024-03-07 0007.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class ScriptService {
    private readonly appKey: string;
    private readonly memberId: string;
    private readonly devAppKey: string[] = ['1hv38ayju6ecz1hv38ayju', 'uv94xxw8mypkuv94xxw8my', '1jy7ogo86aypd1jy7ogo86', 'urxxgofd5s1gurxxgofd5s'];
    private readonly godoMemberId = '{=gSess.memId}';
    private readonly makeMemberId = '<!--/user_id/-->';
    constructor(param: {}) {
        this.appKey = param['eid'];
        this.memberId = param['memberId']
                && param['memberId'].indexOf(this.godoMemberId) === -1
                && param['memberId'].indexOf(this.makeMemberId) === -1
                && param['memberId'].indexOf('input_login_id') === -1
            ? param['memberId'] : undefined;
    }
    excute(tkid: string): void {
        const ajax = AJAX.getInstance();
        let options: AJAXOption = {
            timeout: 10000,
            setHeader: [
                {headerName:"Accept" ,headerValue:"*/*"},
                {headerName:"Accept-Language", headerValue:"gzip"}
            ]
        };
        const url = this.devAppKey.includes(this.appKey)
            ? APIS[ApiType.CDN_EVENT_INFO]['dev'] + this.appKey + '.json'
            : APIS[ApiType.CDN_EVENT_INFO]['prod'] + this.appKey + '.json';
        ajax.get(url, options,(xhr, response, hasTransmitted) => {
            if(hasTransmitted) {
                this.ajaxResponse(response, tkid);
            } else {
                /*let data = {
                    "appkey": this.appKey,
                    "xhrStatus": xhr.status,
                    "currUrl": window.location.href,
                    "referer": document.referrer,
                    "tkid": tkid,
                    "userAgent": navigator.userAgent,
                    "version": window['TCS']['VERSION']
                };
                Telegram.getInstance(this.appKey).send("cdn config file error addAccept", data);*/

                if(xhr.status === 0) {
                    this.retryLoadConfig(url, options, tkid!);
                }
            }

            xhr.onerror = () => {
                let data = {
                    "appkey": this.appKey,
                    "xhrStatus": xhr.status,
                    "currUrl": window.location.href,
                    "referer": document.referrer,
                    "tkid": tkid,
                    "userAgent": navigator.userAgent,
                    "version": window['TCS']['VERSION']
                };
                Telegram.getInstance(this.appKey).send("cdn config file xhr error", data);
            }
        });
    }

    private retryLoadConfig(url: string, options: AJAXOption, tkid: string): void {
        const ajax = AJAX.getInstance();

        ajax.get(url, options, (xhr, response, hasTransmitted) => {
            if(hasTransmitted) {
                this.ajaxResponse(response, tkid);
            } else {
                // let data = {
                //     "appkey": this.appKey,
                //     "xhrStatus": xhr.status,
                //     "currUrl": window.location.href,
                //     "referer": document.referrer,
                //     "version": window['TCSTAG']['VERSION']
                // };
                // Telegram.getInstance(this.appKey).send("[TCSTAG / retry] cdn config file error", data);
            }

            xhr.onerror = () => {
                let data = {
                    "appkey": this.appKey,
                    "xhrStatus": xhr.status,
                    "currUrl": window.location.href,
                    "referer": document.referrer,
                    "tkid": tkid,
                    "userAgent": navigator.userAgent,
                    "version": window['TCSTAG']['VERSION']
                };
                Telegram.getInstance(this.appKey).send("[TCSTAG / retry] cdn config file xhr error", data);
            }
        });
    }

    private ajaxResponse(response: string, tkid: string): void {
        if(!StringUtil.isEmpty(response as string)) {
            const parsedData = JSON.parse(response as string);
            const scriptInfo: Array<ScriptInfo> = parsedData['script'];
            if(scriptInfo) {
                if(document.body == null) {
                    const interval = window.setInterval(() => {
                        if(document.querySelector('body')) {
                            window.clearInterval(interval);
                            this.excuteScript(scriptInfo, tkid);
                        }
                    }, 300);
                } else {
                    this.excuteScript(scriptInfo, tkid);
                }

            }
        }
    }

    private excuteScript(scriptInfoList: Array<ScriptInfo>, tkid: string): void {
        new ExternalSErvice().appendTagFunc();
        scriptInfoList.forEach((scriptInfo) => {
            //TODO "/>" or "/script> 방어로직 추가 필요
            if(scriptInfo.scriptContent.indexOf("<script") === 0) {
                if(URLUtil.checkPath(scriptInfo.path, location.pathname)) {
                    try {
                        //고도몰 아이디 체크
                        if(scriptInfo.scriptContent.indexOf(this.godoMemberId) !== -1) {
                            scriptInfo.scriptContent = scriptInfo.scriptContent.replace(this.godoMemberId, !StringUtil.isUndefined(this.memberId) ? this.memberId : '');
                        } else if(scriptInfo.scriptContent.indexOf(this.makeMemberId) !== -1) {
                            scriptInfo.scriptContent = scriptInfo.scriptContent.replace(this.makeMemberId, !StringUtil.isUndefined(this.memberId) ? this.memberId : '');
                        } else {
                            scriptInfo.scriptContent = scriptInfo.scriptContent.replace(this.godoMemberId, '');
                            scriptInfo.scriptContent = scriptInfo.scriptContent.replace(this.makeMemberId, '');
                        }

                        const frag = document.createRange().createContextualFragment(scriptInfo.scriptContent);
                        document.body.appendChild(frag);
                    } catch (e) {
                        let errorJson = {
                            "appKey": this.appKey,
                            "tkid": tkid,
                            "scriptId": scriptInfo.scriptId,
                            "curUrl" : window.location.href,
                            "error": e
                        };
                        new Telegram(this.appKey).send("script parsing error", errorJson);
                    }
                }
            }
        });
    }
}
