/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-02-23
 */

import {CookieUtil} from "../lib/util/CookieUtil";
import {IAuidInfo} from "../../dm/interface/IAuidInfo";

/**
 * create on 2023-02-23.
 * <p> AUID 정보를 가져오기 위한 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class AuidInfo {
    private readonly MOBON_AUID_INFO_KEY = 'identData';
    private readonly TRACKER_AUID_INFO_KEY = '_uni';
    private static instance: AuidInfo;


    static getInstance(): AuidInfo {
        if(!AuidInfo.instance) {
            AuidInfo.instance = new AuidInfo();
        }
        return AuidInfo.instance;
    }

    /**
     *  추후 통합스크립트로 완전히 통합된 경우 MOBON AUID 가져오는 로직 제거 필요
     */
    getAuid(): string | undefined {
        if(window.localStorage.getItem(this.TRACKER_AUID_INFO_KEY)) {
            return JSON.parse(window.localStorage.getItem(this.TRACKER_AUID_INFO_KEY)!)['identKey'];
        } else if(window.localStorage.getItem(this.MOBON_AUID_INFO_KEY)) {
            return JSON.parse(window.localStorage.getItem(this.MOBON_AUID_INFO_KEY)!)['identKey'];
        } else if(CookieUtil.getInstance().checkCookie('au_id')) {
            return CookieUtil.getInstance().getCookie('au_id');
        }
        return undefined;
    }

    getAuidInfo(): IAuidInfo | null {
        if(window.localStorage.getItem(this.TRACKER_AUID_INFO_KEY)) {
            let parsedData = JSON.parse(window.localStorage.getItem(this.TRACKER_AUID_INFO_KEY)!);
            return {
                auid: parsedData['identKey'],
                ip: parsedData['identNumber']
            }
        } else if(window.localStorage.getItem(this.MOBON_AUID_INFO_KEY)) {
            let parsedData = JSON.parse(window.localStorage.getItem(this.MOBON_AUID_INFO_KEY)!);
            return {
                auid: parsedData['identKey'],
                ip: parsedData['identNumber']
            }
        } else if(CookieUtil.getInstance().checkCookie('au_id')) {
            return {
                auid: CookieUtil.getInstance().getCookie('au_id')
            }
        }
        return null;
    }
}
