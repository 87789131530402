/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-07-12
 */

import { v4, v5 } from 'uuid';
import {CookieUtil} from "../lib/util/CookieUtil";

/**
 * create on 2023-07-12.
 * <p> TK_ID 정보를 가져오기 위한 클래스 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class TkIdInfo {
    private readonly TK_ID_KEY: string = 'tk_id';
    private readonly mergeKey: string = '5efa716f-ac14-4f01-bee7-983ebc4ed007';
    private static instance: TkIdInfo;


    static getInstance(): TkIdInfo {
        if(!TkIdInfo.instance) {
            TkIdInfo.instance = new TkIdInfo();
        }
        return TkIdInfo.instance;
    }



    getTkId(): string | undefined {
        if(window.localStorage.getItem(this.TK_ID_KEY)) {
            return window.localStorage.getItem(this.TK_ID_KEY)!;
        }else if(CookieUtil.getInstance().checkCookie(this.TK_ID_KEY)) {
            return CookieUtil.getInstance().getCookie(this.TK_ID_KEY);
        }
        return undefined;
    }

    setTkId(): string {
        let tkid = '';
        if(!CookieUtil.getInstance().checkCookie('tk_id')) {
            tkid = v4();
            window.localStorage.setItem(this.TK_ID_KEY, tkid);
            CookieUtil.getInstance().setCookie('tk_id', tkid);
        } else {
            tkid = CookieUtil.getInstance().getCookie('tk_id');
        }

        return tkid;
    }

    setMergeEmail(email: string): string {
        const mergeTkId = v5(email, this.mergeKey);
        window.localStorage.setItem(this.TK_ID_KEY, mergeTkId);
        CookieUtil.getInstance().setCookie('tk_id', mergeTkId);
        return mergeTkId;
    }
}
