/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-06-29
 */

import {CallBackFunction, HttpMethod} from "../../enum/GlobalType";
import {AJAXOption} from "./AJAXOption";

/**
 * create on 2023-06-29.
 * <p> ajax 통신 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class AjaxCore {
    private xhr!: XMLHttpRequest;
    private _options: AJAXOption;

    constructor(options: AJAXOption | null) {
        this._options = options
            ? options
            : {timeout: 3000};
    }

    abort() {
        this.xhr.abort();
    }

    get(url: string, callback?: CallBackFunction, async = true) {
        try{
            this.open('GET', url, async, callback);
            this.xhr.send(null);
        } catch (e) {
            this.xhr.abort();
        }

    }

    post(url: string, data: {} | null, callback?: CallBackFunction, async = true) {
        try {
            this.open('POST', url, async, callback);
            data !== null
                ? this.xhr.send(JSON.stringify(data))
                : this.xhr.send(null);
        } catch (e) {

        }
    }

    open(method: HttpMethod, url: string, async: boolean, callback?: CallBackFunction) {
        this.xhr = new XMLHttpRequest();

        const callbackListener = () => {
            let hasTransmitted = false;
            switch (this.xhr.readyState) {
                case this.xhr.UNSENT:
                    /* open() has not been called yet. Request not initialized. */
                    break;
                case this.xhr.OPENED:
                    /* open() has been called. Server connection established. */
                    break;
                case this.xhr.HEADERS_RECEIVED:
                    /* send() has been called, and headers and status are available. */
                    break;
                case this.xhr.LOADING:
                    /* Processing request. */
                    break;
                case this.xhr.DONE:
                    /* Request finished and response is ready. */
                    hasTransmitted = this.hasTransmitted(method, this.xhr.status);
                    if (callback) {
                        callback(this.xhr, this.xhr.response, hasTransmitted);
                    }
                    break;
                default:
                    break;
            }
        }

        this.xhr.open(method, url, async);

        if(async) {
            this.xhr.timeout = this._options.timeout;
        }

        if(this._options.withCredentials) {
            this.xhr.withCredentials = this._options.withCredentials;
        }

        switch (method) {
            case 'GET':
                this.setHeader();
                this.xhr.onreadystatechange = callbackListener;
                break;
            case 'POST':
                this.xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
                this.setHeader();
                this.xhr.onreadystatechange = callbackListener;
                break;
            default:
                throw new TypeError('Invalid Method Type');
        }
    }

    private hasTransmitted(method: HttpMethod, xhrStatus: number): boolean {
        switch (method) {
            case 'GET':
                return xhrStatus === 200;
            case 'POST':
                return xhrStatus === 200 || xhrStatus === 201;
            default:
                throw new TypeError('Invalid HTTP method type');
        }
    }

    private setHeader(): void {
        if(this._options.setHeader !== undefined) {
            this._options.setHeader.forEach(header => {
                this.xhr.setRequestHeader(header.headerName, header.headerValue);
            })

        }
    }

}
