/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-01-11
 */

import {CallBackFunction} from "../../enum/GlobalType";
import {AjaxCore} from "./AjaxCore";

/**
 * create on 2023-01-11.
 * <p> <code>AJAXerCore</code> 클래스의 기능의 호출 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class AJAX {
    private static instance: AJAX;

    private xhr!: XMLHttpRequest;

    constructor() {
    }


    static getInstance(): AJAX {
        if(!AJAX.instance) {
            AJAX.instance = new AJAX;
        }
        return AJAX.instance;
    }

    get(url: string, options, callback?: CallBackFunction, async = true): void {
        const ajaxCore = new AjaxCore(options);
        ajaxCore.get(url, callback, async);
    }

    post(url: string, data: {} | null, options, callback?: CallBackFunction,async = true): void {
        const ajaxCore = new AjaxCore(options);
        ajaxCore.post(url, data, callback , async);
    }

}
