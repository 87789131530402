/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-12-22 0022
 */
import {StringUtil} from "./StringUtil";

/**
 * create on 2023-12-22 0022.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class URLUtil {
    public static checkPath(pathArr: Array<string>, urlPath: string, adverId?: string): boolean {
        urlPath = decodeURIComponent(urlPath);
        try {
            for(let i=0; i<pathArr.length; i++) {
                //TODO 사피 광고주 예외 처리 추후 제거
                if(adverId && adverId === 'ssafy_mulcam') {
                    urlPath = window.location.href.replace(window.location.origin, '');
                    if(pathArr[i] === urlPath || pathArr[i] === urlPath.substring(1)) {
                        return true;
                    }
                }
                let splitValue: string[] = [];
                if(pathArr[i].indexOf('|') > -1) {
                    splitValue = pathArr[i].split('|');
                } else {
                    if(pathArr[i].indexOf('?') > -1) {
                        pathArr[i] = pathArr[i].split('?')[0];
                    }

                    if(StringUtil.isDomainFormat(pathArr[i])) {
                        pathArr[i] = StringUtil.removeDomain(pathArr[i]);
                    }

                    if(StringUtil.isExtensionFormat(pathArr[i])) {
                        //확장자 형태인 경우 ex) join.html 비교 후 리턴
                        if(pathArr[i] === urlPath || pathArr[i] === urlPath.substring(1)) {
                            return true;
                        }
                    } else if (pathArr[i] === '/') {
                        if(pathArr[i] === urlPath) {
                            return true;
                        }
                    } else if (pathArr[i] === '*') {
                        return true;
                    } else {
                        let pathValue = pathArr[i].trim();
                        if(pathValue.indexOf('/') === 0) pathValue = pathValue.substring(1);
                        if(pathValue.lastIndexOf('/') === pathValue.length-1) pathValue = pathValue.substring(0, pathValue.length-1);

                        splitValue = pathValue.split('/');
                    }
                }

                if(pathArr[i] !== '/') {
                    if(urlPath.indexOf('/') === 0) urlPath = urlPath.substring(1);
                    if(urlPath.lastIndexOf('/') === urlPath.length-1) urlPath = urlPath.substring(0, urlPath.length-1);

                    const splitUrlPath = urlPath.split('/');
                    for(let j=0; j< splitValue.length; j++) {
                        if(!splitUrlPath[j] || (splitValue[j] !== splitUrlPath[j] && StringUtil.isNotEmpty(splitValue[j]) && !StringUtil.isBraceFormat(splitValue[j]))) {
                            break;
                        }

                        if(j === splitValue.length -1) {
                            return true;
                        }
                    }
                }
            }
        } catch (e) {}

        return false;
    }
}
