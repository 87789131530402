/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-01-11
 */

/**
 * create on 2023-01-11.
 * <p> 공통 enum </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */

export enum ApiType {
    TRACKER = 'tracker',
    ADVER_INFO = 'adverInfo',
    CDN_EVENT_INFO = 'cdnEventInfo',
    UNI1_INFO = 'uni1ID',
    UNI1_MERGE = 'uni1Merge',
    UNI1_LEAP_DEPARTURE = 'uni1LeapDeparture',
    UNI1_LEAP_ARRIVAL = 'uni1LeapArrival',
    AU_ID = 'auid',
    AAID_MERGE = 'aaIdMerge'
}

export enum EventType {
    PAGE_VIEW = '00001',
    PRODUCT = '00002',
    MEMBER_JOIN = '00003',
    MEMBER_LOGIN = '00004',
    MEMBER_MODIFY = '00005',
    MEMBER_WITHDRAWAL = '00006',
    CART = '00007',
    WISH = '00008',
    ORDER = '00009',
    ORDER_BOUNCE = '00010',
    ORDER_CANCEL = '00011',
    KAKAO_PAY = '10001',
    NAVER_PAY = '10002',
    PAYCO_PAY = '10005',
    CUSTOM = '99999'

}


export enum TriggerType {
    pageLoad = '00001',
    click = '00002',
    submit = '00003'
}

export enum HostingType {
    CAFE24_SMART = '00001',
    GODOMALL_RENT = '00002',
    MAKESHOP_D4 = '00003',
    SELFHOST = '99999'
}

export namespace TriggerType {
    const reverseMap = new Map<string, string>();
    Object.keys(TriggerType).forEach((s: string) => {
        const e = (<any>TriggerType)[s];
        reverseMap.set(e.toString(), s);
    });
    export function valueOf(str: string) {
        return reverseMap.get(str);
    }
}

export enum SnsType {
    KAKAO = '00001',
    NAVER = '00002',
    FACEBOOK = '00003',
    INSTAGRAM = '00004',
    GOOGLE = '00005',
    APPLE = '00006',
    ETC = '99999'

}

export enum ExternalFuncType {
    MEMBER_JOIN = 'join',
    MEMBER_LOGIN = 'login'
}

export enum SelectorType {
    QUERY_SELECTOR = '00001',
    ID = '00002',
    NAME = '00003',
    CLASS_NAME = '00004',
    CURRENT_URL = '00005',
    REFERER = '00006',
    WINDOW = '00007'
}

export enum HTTPMethod {
    GET = 'get',
    POST = 'post'
}

export enum DevType {
    DEV = 'dev',
    PROD = 'prod'
}

export const APIS: { [apiType in ApiType]: { [devType in DevType | string]: string } } = {
    'tracker': {
        'dev': 'https://gw.tune720.com/api/v3/tracking',
        'prod':'https://gw.tune720.com/api/v3/tracking'
    },
    'adverInfo': {
        'dev': 'https://crm.mediacategory.com/api/tracker/adver-info/',
        'prod': 'https://crm.mediacategory.com/api/tracker/adver-info/'
    },
    'cdnEventInfo' : {
        'dev': 'https://cdn.onetag.co.kr/tcs/cf/d/',
        'prod': 'https://cdn.onetag.co.kr/tcs/cf/p/',
    },
    'uni1ID' : {
        'dev': 'https://stage-dispatch.uni1id.com/finale/',
        'prod': 'https://dispatch.uni1id.com/finale/'
    },
    'uni1Merge': {
        'dev': 'https://stage-union.uni1id.com/id',
        'prod': 'https://union.uni1id.com/id'
    },
    'uni1LeapDeparture': {
        'dev': 'https://stage-union.uni1id.com/leap/departure',
        'prod': 'https://union.uni1id.com/leap/departure'
    },
    'uni1LeapArrival': {
        'dev': 'https://stage-union.uni1id.com/leap/arrival',
        'prod': 'https://union.uni1id.com/leap/arrival'
    },
    'auid': {
        'dev': 'https://uniid.mediacategory.com/api/v1/uni',
        'prod': 'https://uniid.mediacategory.com/api/v1/uni'
    },
    'aaIdMerge': {
        'dev': 'https://stage-union.uni1id.com/id/patch',
        'prod': 'https://union.uni1id.com/id/patch'
    }
};


export enum KeywordType {
    COMMON = 'common',
    INTERNAL = 'internal',
    EXTERNAL = 'external'

}
export enum KeywordSessionType {
    COMMON = 'common',
    SESSION = 'session', //세션
    INDIRECT = 'indirect' //간접
}

export enum SearchDomain {
    DAUM = 'daum',
    NAVER = 'naver',
    YAHOO = 'yahoo',
    MSN = 'msn',
    BING = 'bing',
    ZUM = 'zum'
}



/* 인코딩 목록 */
export enum EncodingList {
    ASCII = 'ascii',
    UNICODE = 'unicode',
    UTF_8 = 'utf-8',
    EUC_KR = 'euc-kr',
    ISO_8859_8 = 'iso-8859-8',
    ISO_8859_16 = 'iso-8859-16'
}

