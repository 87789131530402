/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-06-07
 */

import {TelegramMessageForm} from "./TelegramMessageForm";

/**
 * create on 2023-06-07.
 * <p> 클래스 설명 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class TelegramMessage {
    protected _message: string;
    private telegramMessageForm: TelegramMessageForm;

    constructor() {
        this._message = '';
        this.telegramMessageForm = new TelegramMessageForm();
    }

    get message(): string {
        return this._message;
    }

    setTitle(title: string): void {
        this._message += this.telegramMessageForm.text(title).bold().build();
    }

    setInfoMessage(param: {} | null): void {
        if(param != null) {
            const keys = Object.keys(param);
            for(let key of keys) {
                this._message += this.telegramMessageForm.text(`${key} : ${param[key]}`).build();
            }
        }
    }

    resetMessage(): void {
        this._message = '';
    }
}
