import {ObserverConfig} from "./EventCommonObserver";

/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-12-21 0021
 */
/**
 * create on 2023-12-21 0021.
 * <p> 동적 tag 삽이 observer </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class AppendObserver {
    private bodyElement: HTMLElement | null;
    private readonly observerConfig: ObserverConfig;
    constructor() {
        this.bodyElement = document.body;
        this.observerConfig = {
            childList: true,
            subtree: true
        }
    }

    observe(targetSelector: string, element: HTMLElement): void {
        let observer = new MutationObserver((mutations, observer) => {
            for(const mutation of mutations) {
                try {
                    if(mutation.type === 'childList') {
                        const targetElement = document.querySelector(targetSelector);
                        if(targetElement) {
                            targetElement.after(element);
                            observer.disconnect();
                        }
                    }
                } catch (e) {
                    observer.disconnect();
                }
            }
        });

        if(this.bodyElement == null) {
            let interval = window.setInterval(() => {
                this.bodyElement = document.body;
                if(this.bodyElement) {
                    observer.observe(this.bodyElement, this.observerConfig);
                    clearInterval(interval);
                }
            }, 300);
        } else {
            observer.observe(this.bodyElement, this.observerConfig);
        }
    }
}
