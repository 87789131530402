/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2024-03-07 0007
 */
import {StringUtil} from "../core/src/common/lib/util/StringUtil";
import {OneTagService} from "../core/src/onetag/service/OneTagService";
import {OTScriptVersion} from "../core/src/onetag/OneTagScriptVersion";

/**
 * create on 2024-03-07 0007.
 * <p> onetag 스크립트 시작 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
(() => {
    const script: NodeListOf<HTMLScriptElement> = document.querySelectorAll('script[src*="tcs.js"]');
    if(script.length > 1) {
        script[1].remove();
        return;
    }

    window['ONETAG'] = window['ONETAG'] || function() {(window['ONETAG'] = window['ONETAG'] || [])};
    window['ONETAG']['VERSION'] = OTScriptVersion.version;

    const queryString = script[0]!.src.split('?')[1];
    if(!StringUtil.isEmpty(queryString)) {
        let data = {};
        const splitData = queryString.split('&');
        for(let i=0; i<splitData.length; i++) {
            const keyValue = splitData[i].split('=');
            data[keyValue[0]] = keyValue[1];
        }
        new OneTagService().command(data);

    }
})();