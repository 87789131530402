/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-06-07
 */

import {TelegramMessage} from "./TelegramMessage";
import {AJAX} from "../ajax/AJAX";
import {AJAXOption} from "../ajax/AJAXOption";

/**
 * create on 2023-06-07.
 * <p> 텔레그램 알림 전송 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class Telegram {
    private readonly testAppKey: string[] = ['1hv38ayju6ecz1hv38ayju', 'uv94xxw8mypkuv94xxw8my', '1jy7ogo86aypd1jy7ogo86', 'urxxgofd5s1gurxxgofd5s'];

    /*telegram token id*/
    private readonly token_id: string = '6194305575:AAF1mXyiHSdRsRTfYkPpsW6G4FnzsXVMpWw';
    private readonly dev_token_id: string = '5988530668:AAHUbndjF0KYCgkovxhjVw4P9HZV9iQ6rPE';
    /*telegram chat id*/
    private readonly chat_id: string = '-1001957811179';
    private readonly dev_chat_id: string = '-1001654394959';
    /*telegram text 사용 모드*/
    private readonly parseMode: string = 'HTML'

    private telegramMessage: TelegramMessage;
    private data: {};

    private static instance: Telegram;
    private appkey: string | undefined;


    constructor(appkey?: string) {
        this.telegramMessage = new TelegramMessage();
        this.data = {};
        this.appkey = appkey;

    }

    public static getInstance(appkey?: string): Telegram {
        if(!Telegram.instance) {
            Telegram.instance = new Telegram(appkey);
        }
        return Telegram.instance;
    }

    send(title: string, infoMessage: {} | null): void {
        let url: string = this.adverCheck()
            ? `https://api.telegram.org/bot${this.dev_token_id}/sendMessage`
            :`https://api.telegram.org/bot${this.token_id}/sendMessage`;

        this.telegramMessage.setTitle(title);
        this.telegramMessage.setInfoMessage(infoMessage);

        let data = {};
        data['chat_id'] = this.adverCheck() ? this.dev_chat_id : this.chat_id;
        data['parse_mode'] = this.parseMode;
        data['text'] = this.telegramMessage.message;

        const ajax = AJAX.getInstance();
        let options: AJAXOption = {timeout:1500}
        ajax.post(url, data, options);
        this.telegramMessage.resetMessage();
    }

    private adverCheck(): boolean {
        if(this.appkey && this.testAppKey.indexOf(this.appkey) > -1) {
            return true;
        }
        return false;
    }
}
