/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-06-22
 */

import {AJAX} from "../lib/ajax/AJAX";
import {APIS, ApiType, DevType} from "../enum/GlobalContents";
import {StringUtil} from "../lib/util/StringUtil";
import {Telegram} from "../lib/telegram/Telegram";
import {AJAXOption} from "../lib/ajax/AJAXOption";
import {CookieUtil} from "../lib/util/CookieUtil";
import {AuidInfo} from "../auidInfo/AuidInfo";
import {QueryString} from "../lib/url/QueryString";
import { v4 } from 'uuid';

/**
 * create on 2023-06-22.
 * <p> uni1 병합 및 조회 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class Uni1Info {
    private static instance: Uni1Info;
    private readonly uni1IdKey: string = '_unv';

    static getInstance(): Uni1Info {
        if(!Uni1Info.instance) {
            Uni1Info.instance = new Uni1Info();
        }
        return Uni1Info.instance;
    }

    detectRobot(userAgent: string) {
        let robots = new RegExp([
            /bot/,/spider/,/crawl/,                            // GENERAL TERMS
            /APIs-Google/,/AdsBot/,/Googlebot/,                // GOOGLE ROBOTS
            /mediapartners/,/Google Favicon/,
            /FeedFetcher/,/Google-Read-Aloud/,
            /DuplexWeb-Google/,/googleweblight/,
            /bing/,/yandex/,/baidu/,/duckduck/,/yahoo/,        // OTHER ENGINES
            /ecosia/,/ia_archiver/,
            /facebook/,/instagram/,/pinterest/,/reddit/,       // SOCIAL MEDIA
            /slack/,/twitter/,/whatsapp/,/youtube/,
            /semrush/,/HeadlessChrome/,                         // OTHER
            /Yeti/ ,/AdsBot-Google/, /PetalBot/, /FacebookBot/,
            /AhrefsBot/ ,/pingbot/ ,/Linespider/ ,/Pinterestbot/,
            /WebPageTest.org bot/ ,/SMTBot/ ,/YisouSpider/,
            /Storebot/ ,/GooglePlusBot/ ,/Datanyze/ ,/SiteAuditBot/,
            /Free Traffic Exchange Bot/, /Investment Crawler/,
            /Applebot/, /Mail.RU_Bot/ ,/PagePeeker/ ,/Webcrawler/,
            /Python-urllib/ ,/LightspeedSystemsCrawler/ ,/BomboraBot/,
            /MSIECrawler/ ,/MicroAdBot/ ,/YandexAccessibilityBot/,
            /PowerApp_hWscJBot/
        ].map((r) => r.source).join("|"),"i");                 // BUILD REGEXP + "i" FLAG

        if(robots.test(userAgent)) {
            return true;
        } else {
            if(userAgent.toLowerCase().indexOf('bot') > 0) {
                return true;
            } else {
                return false;
            }
        }
    }

    //auid, adid, aaid 병합
    mergeUn1ID(adbId: string, tkid: string): void {
        this.detectIncognitoMode(adbId, tkid);
    }

    //시크릿 모드 체크
    private detectIncognitoMode(adbId: string, tkid: string): void {
        //3rd cookie 확인
        this.loadAuidCall(() => {
            let rtnObj: {} = window['retrieveMobonAuidAndIpInfo']();
            this.setFirstPartyCookie(rtnObj['auId'], 'au_id', 'uni_merge', adbId, tkid);
        });
    }

    private loadAuidCall(callback): void {
        let script = document.createElement('script');
        script.src = 'https://www.mediacategory.com/script/common/media/0';
        script.onload = () => callback(script);
        document.head.appendChild(script);
    }

    private setFirstPartyCookie(uniId: string, cookieName: string ,mergeCheckId: string, adbId: string, tkid: string): void {
        const checkUniId = uniId.toLocaleLowerCase();
        if(!this.detectRobot(navigator.userAgent)) {
            if(!StringUtil.isStrictEmpty(checkUniId)) {
                const firstId = CookieUtil.getInstance().getCookie(cookieName);
                const firstIdMerge = CookieUtil.getInstance().getCookie(mergeCheckId);

                if(!firstIdMerge || firstId !== uniId || Number(firstIdMerge) !== 1) {
                    CookieUtil.getInstance().setCookie(cookieName, uniId);
                    this.mergeCallUniId(uniId, mergeCheckId, adbId);
                } else {
                    this.mergeAaId(uniId, adbId);
                }
                //adid 병합
                this.mergeAdId(uniId);
            } else {
                this.mergeAaId(tkid, adbId);
            }
        }
    }

    //uniId 병합
    private mergeCallUniId(uniId: string, mergeCheckId: string, adbId: string): void {
        const ajax = AJAX.getInstance();
        const url = APIS[ApiType.UNI1_MERGE][DevType.PROD] + '?mergeId=' + uniId + '&mergeType=AUID&platformType=UNITY&targetType=ADVERTISER&id=' + uniId;
        const options: AJAXOption = {timeout: 1500, setHeader: [{headerName: 'Module', headerValue: 'REFINEMENT'}]}
        ajax.post(url, null, options, (xhr, response, hasTransmitted) => {
            if(hasTransmitted) {
                CookieUtil.getInstance().setCookie(mergeCheckId, 1, 30);
                // const uni1Id = JSON.parse(xhr.response)['data']['universalId'];
                // CookieUtil.getInstance().setCookie(this.uni1IdKey, uni1Id);

                //auid 병합 호출 후 aaid 병합 호출
                this.mergeAaId(uniId, adbId);

            }

            xhr.onerror = () => {
                let data = {"status": xhr.status, "currUrl": location.href, "version": window['TCS']['VERSION']};
                Telegram.getInstance().send('uni1IdMerge error', data);
            }
        });
    }

    getUniversalId(): string {
        return CookieUtil.getInstance().getCookie(this.uni1IdKey);
    }

    setUniversalId(): void {
        const auid = AuidInfo.getInstance().getAuid();
        if(!StringUtil.isStrictEmpty(auid)) {
            const ajax = AJAX.getInstance();
            const url = APIS[ApiType.UNI1_INFO][DevType.PROD] + auid + '?idType=UNIQUE';
            const options: AJAXOption = {timeout:1500}
            ajax.get(url, options, (xhr, response, hasTransmitted) => {
                if(hasTransmitted) {
                    const universalId = JSON.parse(xhr.response)['data']['universalId'];
                    CookieUtil.getInstance().setCookie(this.uni1IdKey, universalId);
                }
            });

        }
    }

    mergeLeapDeparture(referenceId: string, unique?: string): void {
        const uniId = StringUtil.isStrictEmpty(unique) ? v4() : unique;
        const ajax = AJAX.getInstance();
        const options: AJAXOption = {timeout:1500}
        const parameter = 'id=' + referenceId + '&uniqueId=' + uniId + '&targetType=ADVERTISER&platformType=UNITY'
        const url = APIS[ApiType.UNI1_LEAP_DEPARTURE][DevType.PROD] + '?' + parameter;

        ajax.post(url, null, options, (xhr, response, hasTransmitted) => {
            xhr.onerror = () => {
                new Telegram().send('mergeLeafDeparture error', {"status": xhr.status, "uniId": uniId, "currUrl": window.location.href});
            }
        });
    }

    mergeLeapArrive(uniId: string) {
        const referenceId = new QueryString().getValue(window.location.href, 'referenceId');
        if(StringUtil.isStrictEmpty(referenceId)) {
            return;
        }
        const parameter = 'id=' + referenceId + '&uniqueId=' + uniId + '&targetType=ADVERTISER&platformType=UNITY';
        const url = APIS[ApiType.UNI1_LEAP_ARRIVAL][DevType.PROD] + '?' + parameter;
        const ajax = AJAX.getInstance();
        const options: AJAXOption = {timeout:1500}
        ajax.post(url, null, options, (xhr, response, hasTransmitted) => {
            xhr.onerror = () => {
                new Telegram().send('mergeLeafArrive error', {"status": xhr.status, "uniId": uniId, "currUrl": window.location.href});
            }
        });
    }

    mergeAaId(auid: string, adbId: string): void {
        if(StringUtil.isNotStrictEmpty(adbId)) {
            const ajax = AJAX.getInstance();
            const options: AJAXOption = {timeout: 1500}
            const parameter = 'id=' + auid + '&mergeId=' + adbId + '&mergeType=AAID&targetType=ADVERTISER&platformType=UNITY'
            const url = APIS[ApiType.AAID_MERGE][DevType.PROD] + '?' + parameter;

            ajax.post(url, null, options, (xhr, response, hasTransmitted) => {
                xhr.onerror = () => {
                    new Telegram().send('mergeAaId error', {"status": xhr.status, "auid": auid, "adbId": adbId, "currUrl": window.location.href});
                }
            });
        }

    }

    mergeAdId(auid: string): void {
        const adid = new QueryString().getValue(window.location.href, 'adid');
        if(StringUtil.isNotStrictEmpty(adid)) {
            const ajax = AJAX.getInstance();
            const options: AJAXOption = {timeout: 1500}
            const parameter = 'id=' + auid + '&mergeId=' + adid + '&mergeType=ADID&targetType=ADVERTISER&platformType=UNITY'
            const url = APIS[ApiType.UNI1_MERGE][DevType.PROD] + '?' + parameter;

            ajax.post(url, null, options, (xhr, response, hasTransmitted) => {
                xhr.onerror = () => {
                    new Telegram().send('mergeAdId error', {"status": xhr.status, "auid": auid, "adid": adid, "currUrl": window.location.href});
                }
            });
        }
    }

}
