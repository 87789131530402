/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-09-13 0013
 */

/**
 * create on 2023-09-13.
 * <p> 쿠키 util </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class CookieUtil {
    private static instance: CookieUtil;


    static getInstance(): CookieUtil {
        if(!CookieUtil.instance) {
            CookieUtil.instance = new CookieUtil();
        }
        return CookieUtil.instance;
    }

    getCookie(key: string): string {
        let result = "";
        const cookie = document.cookie;

        const splitValue = cookie.split("; ");
        for(let i=0; i<splitValue.length; i++) {
            if(splitValue[i].split("=")[0] === key) {
                result = splitValue[i].split("=")[1];
                break;
            }
        }
        return result;
    }

    setCookie(key: string, value: string | number, expireDay?: number): void {
        const hostname: string = '.' + location.hostname;
        const expireDate = new Date();
        if(expireDay === undefined) {
            expireDay = 730;
        }
        expireDate.setDate(expireDate.getDate() + expireDay);

        let cookieText = key + '=' + value;
        cookieText += ('; EXPIRES=' + expireDate.toUTCString());
        cookieText += ('; PATH=/');
        cookieText += ('; DOMAIN=' + hostname);
        document.cookie = cookieText;
    }

    checkCookie(key: string): boolean {
        return document.cookie.includes(key);
    }

}
