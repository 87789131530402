/*
 * COPYRIGHT (c) Enliple 2022
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2023-03-06
 */

/**
 * create on 2023-03-06.
 * <p> String util </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class StringUtil {
    private static readonly URL_REG_EXP = /http(s)?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}/g;
    private static readonly EXTENSION_REG_EXP = /(\w|-)+\.[a-z]{2,4}/g;
    private static readonly BRACE_EXP: RegExp = /{{./g;

    static isEmpty(value: any):boolean {
        return value === '';
    }

    static isNotEmpty(value: any): boolean {
        return value !== '';
    }

    static isNull(value: any): boolean {
        return value === null;
    }

    static isNotNull(value: any): boolean {
        return value !== null;
    }

    static isUndefined(value: any): boolean {
        return value === undefined;
    }

    static isStrictEmpty(value: any): boolean {
        return StringUtil.isEmpty(value) || StringUtil.isNull(value) || StringUtil.isUndefined(value);
    }

    static isNotStrictEmpty(value: any): boolean {
        return !this.isStrictEmpty(value);
    }
    
    static stringToNum(value: string): string {
        return value.replace(/[^0-9]/g, '');
    }

    static isDomainFormat(value: string): boolean {
        return new RegExp(this.URL_REG_EXP).test(value);
    }

    static isExtensionFormat(value: string): boolean {
        return new RegExp(this.EXTENSION_REG_EXP).test(value);
    }

    static removeDomain(url): string {
        return url.replace(this.URL_REG_EXP,'')
    }

    static isBraceFormat(value: string) {
        return new RegExp(this.BRACE_EXP).test(value);
    }

    static checkTargetReturnUndefind(strObj: string): string | undefined {
        if(this.isStrictEmpty(strObj)) {
            return undefined;
        }
        return strObj;
    }
}