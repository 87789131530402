/*
 * COPYRIGHT (c) Enliple 2019
 * This software is the proprietary of Enliple
 *
 * @author <a href="mailto:tgkim@enliple.com">tgkim</a>
 * @since 2024-03-07 0007
 */
import {appendInfo} from "../../dm/interface/ExternalFuncInterface";
import {AppendObserver} from "../../dm/observer/AppendObserver";

/**
 * create on 2024-03-07 0007.
 * <p> 외부 사용 함수 제공 </p>
 * <p> {@link } and {@link } 관련 클래스 </p>
 *
 * @version 1.0
 * @author tgkim
 */
export class ExternalSErvice {

    /**
     * 스크립트 append 방식 태그 append
     */
    appendTagFunc(): void {
        window['ONETAG']['appendTag'] = window['ONETAG']['appendTag'] || function(){(window['ONETAG']['appendTag'].q = window['ONETAG']['appendTag'].q || []).push(arguments)};

        const appendTagFunc = () => {
            const appendFunc = window['ONETAG']['appendTag'];
            window['ONETAG']['appendTag'] = function () {
                appendFunc.apply(window, arguments);
                appendTag();
            }
        }

        const appendTag = () => {
            let appendData = window['ONETAG']['appendTag'].q.shift();
            this.appendTagData(appendData[0]);

        }

        appendTagFunc();
    }

    private appendTagData(data: appendInfo) {
        const div = document.createElement('div');
        div.id = data.id;
        const element = document.querySelector(data.selector);
        if(element === null) {
            new AppendObserver().observe(data.selector, div);
        } else {
            element.after(div);
        }
    }
}
